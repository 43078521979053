export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');

    if (serializedState === null) {
      return undefined;
    }

    const parsedState = JSON.parse(serializedState);

    if (!('expiration' in parsedState) || parsedState.expiration < new Date().getTime()) {
      localStorage.removeItem('state')
      return undefined;
    }

    return parsedState
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const now = new Date().getTime() + (1000 * 60 * 10); // 10 minutes

    const serializedState = JSON.stringify({...state, expiration: now});

    localStorage.setItem('state', serializedState);
  } catch {}
};
